// const HOST = 'http://localhost:8000'
// const HOST = 'https://api2.nunsoft.com'
const HOST = process.env.VUE_APP_BACKEND_HOST

const baseURL = HOST + '/api/'

const endpoints = [
  {
    AUTH: [
      { LOGIN: 'login' },
      { HAPPYGAST_LOGIN: 'happygast-login' },
      { REGISTER: 'register' },
      { LOGOUT: 'logout' },
      { PROFILE: 'profile' },
      { CHECK: 'auth/check' },
    ]
  },
  {
    USER: [
      { GET_ALL: 'users' },
      { SHOW: 'users/{id}' },
      { INSERT: 'users' },
      { UPDATE: 'users/{id}' },
      { DELETE: 'users/{id}' },
    ]
  },
  {
    ROLE: [
      { GET_ALL: 'roles' },
    ]
  },
  {
    CATEGORY: [
      { GET_ALL: 'categories' },
      { LIST: 'categories/list' },
    ]
  },
  {
    PRODUCT: [
      { POOL: 'firms/{firm_id}/products/pool' },
      { COPY_TO_RESTAURANT: 'firms/{firm_id}/products/pool/copy-to-restaurant' },
      { COPY_TO_SUPPLIER: 'firms/{firm_id}/products/pool/copy-to-supplier' },
      { GET_ALL: 'firms/{id}/products/{related_firm_id}' },
      { SHOW: 'products/{id}' },
      { VIEW: 'products/{id}/view' },
      { GET_PARENTS: 'products/{id}/parents' },
      { GET_CHILDS: 'products/{id}/childs' },
      { GET_PRODUCT_SUPPLIERS: 'firms/{firm_id}/products/{id}/suppliers' },
      { SET_SUPPLIED_PRODUCT: 'firms/{firm_id}/products/{id}/supplied' },
      { INSERT: 'products' },
      { UPDATE: 'products/{id}' },
      { DELETE: 'products/{id}' },
      { FORCE_DELETE: 'products/{id}/force-delete' },
      { UPLOAD_IMAGE: 'products/images/upload' },
      { UPDATE_ACTIVE: 'products/{id}/update-active' },
      { UPDATE_DEPARTMENT: 'products/{id}/update-department' },

      { IMPORT_XML: 'import/xml/metro' },
      { IMPORT_EXCEL_RESTAURANT: 'restaurant/{firm_id}/products/import/excel' },
      { STORE_NEW_POSITION: 'restaurant/{firm_id}/products/store-new-position' },
      { RE_POSITION: 'restaurant/{firm_id}/products/re-position' },
      { PRINTABLE_LIST: 'restaurant/{firm_id}/products/list' },
      { USED_AS_INGREDIENTS: 'restaurant/{firm_id}/products/used-as-ingredients' },
    ]
  },
  {
    FIRM: [
      { LIST: 'firms/list' },
      { LIST_PRODUCT_POOL: 'firms/{firm_id}/list-product-pool' },
      { FEATURE_LIST: 'firm-feature/{country_id}/list' },
      { USER: 'firms/users/{firm_id}' },
      { PRODUCT_LIST: 'firms/{id}/product-list/{related_firm_id}' }, // related_firm_id zorunlu değil
      { RELATED_FIRMS: 'firms/{firm_id}/related-firms' },
      { RELATED_SUPPLIERS: 'firms/{firm_id}/related-suppliers' },

      { SHOW: 'firms/{id}' },
      { SHOW_BY_HAPPYGAST_ID: 'firms/show/{id}' },
      { UPDATE: 'firms/{id}' },
      { UPLOAD_LOGO: 'firms/images/upload' },
    ]
  },
  {
    SUPPLIER: [
      { SHOW: 'suppliers/{id}' },
      { GET_BUYER_FIRMS: 'suppliers/{supplier_id}/buyer-firms' },
      { SET_BUYER_FIRMS: 'suppliers/{supplier_id}/buyer-firms' },
      { SEND_BUYER_FIRM_REQUEST: 'suppliers/{supplier_id}/buyer-firms/request' },
      { ACCEPT_BUYER_FIRM_REQUEST: 'suppliers/{supplier_id}/buyer-firms/accept' },
      { GET_ALL_PRODUCTS: 'suppliers/{supplier_id}/products' },
      { SHOW_PRODUCT: 'suppliers/{supplier_id}/products/{id}' },
      { STORE_OWN_PRODUCT: 'suppliers/{supplier_id}/products' },
      { UPDATE_OWN_PRODUCT: 'suppliers/{supplier_id}/products/{id}' },
      { DELETE_OWN_PRODUCT: 'suppliers/{supplier_id}/products/{id}' },
      { FORCE_DELETE_OWN_PRODUCT: 'suppliers/{supplier_id}/products/{id}/force-delete' },
      { UPDATE_PRODUCT_PRICES: 'suppliers/{supplier_id}/products/{id}/prices' },

      { PRODUCT_LIST: 'suppliers/{supplier_id}/product-list/{related_firm_id}' },
    ]
  },
  {
    WAREHOUSE: [
      { GET_ALL: 'firm/warehouses/{firm_id}' },
      { LIST: 'firms/{firm_id}/warehouses/list' },
      { INSERT: 'firm/warehouses/{firm_id}' },
      { SHOW: 'warehouses/{id}' },
      { UPDATE: 'warehouses/{id}' },
      { DELETE: 'warehouses/{id}' },
    ]
  },
  {
    WAREHOUSE_PRODUCT: [
      { WAREHOUSE_PRODUCTS: 'warehouse/{id}/products' },
      { SHOW: 'warehouse/{id}/products/{product_id}' },
      { UPDATE: 'warehouse/{id}/products/{product_id}' },
      { UPDATE_QUANTITY: 'warehouse/{id}/products/{product_id}/quantity' },
      { UPDATE_ORDER_DEPARTMENT: 'warehouse/{id}/products/{product_id}/update-order-department' },

      { COPY_TO_WAREHOUSE: 'products/copy-to-warehouse' },
      { REMOVE_FROM_WAREHOUSE: 'products/{id}/remove-from-warehouse' },
      { STOCK_TRANSFER: 'products/{id}/stock-transfer' },
    ]
  },
  {
    SHIPPING_AREAS: [
      { GET_ALL: 'warehouse/{id}/shipping_areas' },
      { LIST: 'warehouse/{id}/shipping_areas/list' },
      { INSERT: 'warehouse/{id}/shipping_areas' },
      { UPDATE_STATUS: 'warehouse/{id}/shipping_areas/{shipping_area_id}' },
      { DELETE: 'warehouse/{id}/shipping_areas/{shipping_area_id}' },
    ]
  },
  {
    ADDRESS: [
      { COUNTRY_LIST: 'country/list' },
      { PROVINCE_LIST: 'country/{country_id}/province/list' },
      { CITY_LIST: 'province/{province_id}/city/list' },
    ]
  },
  {
    PRODUCT_GROUP: [
      { GET_ALL: 'firms/pgroups/{firm_id}' },
      { LIST: 'firms/{firm_id}/pgroups/list' },
      { INSERT: 'firms/{firm_id}/pgroups' },
      { SHOW: 'firms/{firm_id}/pgroups/{id}' },
      { UPDATE: 'firms/{firm_id}/pgroups/{id}' },
      { DELETE: 'firms/{firm_id}/pgroups/{id}' },

      { STORE_NEW_POSITION: 'firms/{firm_id}/pgroups/store-new-position' },
      { RE_POSITION: 'firms/{firm_id}/pgroups/reposition' },
      { UPLOAD_IMAGE: 'firms/{firm_id}/pgroups/images/upload' },
    ]
  },
  {
    MENU: [
      { GET_ALL: 'firms/menus/{firm_id}' },
      { LIST: 'firms/{firm_id}/menus/list' },
      { INSERT: 'firms/{firm_id}/menus' },
      { SHOW: 'firms/{firm_id}/menus/{id}' },
      { UPDATE: 'firms/{firm_id}/menus/{id}' },
      { DELETE: 'firms/{firm_id}/menus/{id}' },

      { STORE_NEW_POSITION: 'firms/{firm_id}/menus/store-new-position' },
      { RE_POSITION: 'firms/{firm_id}/menus/reposition' },
      { UPLOAD_IMAGE: 'firms/{firm_id}/menus/images/upload' },
    ]
  },
  {
    MENU_GROUP: [
      { GET_ALL: 'firms/{firm_id}/menus/groups' },
      { INSERT: 'firms/{firm_id}/menus/groups' },
      { UPDATE: 'firms/{firm_id}/menus/groups/{id}' },
      { DELETE: 'firms/{firm_id}/menus/groups/{id}' },
    ]
  },
  {
    EXTRA: [
      { GET_ALL: 'firms/{firm_id}/extras' },
      { LIST: 'firms/{firm_id}/extras/list' },
      { GET_ALL_GROUPS: 'firms/{firm_id}/extras/groups' },

      { INSERT: 'firms/{firm_id}/extras' },
      { SHOW: 'firms/{firm_id}/extras/{id}' },
      { UPDATE: 'firms/{firm_id}/extras/{id}' },
      { DELETE: 'firms/{firm_id}/extras/{id}' },

      { UPDATE_AMOUNT: 'firms/{firm_id}/extras/{id}/update-amount' },
      { UPDATE_PRICE: 'firms/{firm_id}/extras/{id}/update-price' },
    ]
  },
  {
    OPTION: [
      { LIST: 'firms/{firm_id}/options' },
      { INSERT: 'firms/{firm_id}/options' },
      { UPDATE: 'firms/{firm_id}/options/{id}' },
      { DELETE: 'firms/{firm_id}/options/{id}' },
    ]
  },
  {
    OPTION_GROUP: [
      { GET_ALL: 'firms/{firm_id}/options/groups' },
      { INSERT: 'firms/{firm_id}/options/groups' },
      { UPDATE: 'firms/{firm_id}/options/groups/{id}' },
      { DELETE: 'firms/{firm_id}/options/groups/{id}' },
    ]
  },

  {
    ORDER: [
      { GET_ALL: 'firms/{firm_id}/orders' },
      { SHOW: 'firms/{firm_id}/orders/{str_id}' },

      { SEND: 'send-order' },
    ]
  },
  {
    SUPPLIER_ORDER: [
      { GET_ALL: 'firms/{firm_id}/supplier-orders' },
      { SHOW: 'firms/{firm_id}/supplier-orders/{id}' },
      { UPDATE: 'firms/{firm_id}/supplier-orders/{id}' },
    ]
  },
  {
    ATRIFY: [
      { GET_ALL: 'atrify/items' },
      { GET_ITEMS: 'atrify/items/get' },
      { FETCH_ITEMS: 'atrify/items/fetch' },
    ]
  },
  {
    CASHPAD: [
      { SHOW: 'firms/{firm_id}/cashpad' },
      { UPDATE: 'firms/{firm_id}/cashpad' },
      { IMPORT_DIGITAL_MENU: 'firms/{firm_id}/cashpad/import/digital-menu' },
      { GET_PRODUCTS: 'firms/{firm_id}/cashpad/products' },
      { GET_OPTIONS: 'firms/{firm_id}/cashpad/options' },
      { PAIR_PRODUCTS: 'firms/{firm_id}/cashpad/pair/products' },
      { PAIR_EXTRAS: 'firms/{firm_id}/cashpad/pair/extras' },
      { PAIR_INGREDIENTS: 'firms/{firm_id}/cashpad/pair/ingredients' },
    ]
  },
  {
    UNITCODE: [
      { LIST: 'unit-code/list' }
    ]
  },

  {
    STOCK: [
      { MOVEMENT: 'firms/{firm_id}/stock/stock-movement' }
    ]
  },

  {
    HAPPYGAST: [
      { GET_NEW_RESTAURANTS: 'happy-gast/get-new-restaurants' },
    ]
  }
]

function get(p, c, id) {
  const parentValues = Object.values(endpoints.find(item => item[p]))[0],
    childValues = Object.values(parentValues.find(item => item[c]))[0];
  return baseURL + (
    id ?
    childValues.replace("{id}", id) :
    childValues.replace("{id}", ''));
}

export default {
  HOST,
  baseURL,
  get
}
