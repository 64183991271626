<template>
  <div>
    <CAlert
      :show.sync="delaySuccessAlert"
      closeButton
      color="success"
      class="mb-4"
    >
      <strong>{{ successMessage.itemName }} <span style="font-weight: 400">{{ successMessage.message }}</span></strong>
      <CProgress
        color="success"
        :max="delaySecs"
        :value="delaySuccessAlert"
        height="2px"
      />
    </CAlert>

    <CAlert
      :show.sync="isShowError"
      closeButton
      color="danger"
      class="mb-4"
    >
      <!-- Ex: { "Name": [ "The name may only contain letters." ], "Notes": [ "The notes field is required." ] } -->
      <div v-for="(object, name, index) in errorMessage" :key="index">
        <strong>{{ name | ucfirst }}</strong>
        <ul id="v-for-object">
          <template v-if="!Array.isArray(object)">
            <li>{{ object }}</li>
          </template>
          <template v-else>
            <li v-for="(item, index) in object" :key="index">
              {{item}}
            </li>
          </template>
        </ul>
      </div>
    </CAlert>    
  </div>
</template>

<script>
export default {
  name: 'AlertSection',
  props: {
    successAlertMessage: Object,
    errorAlertMessage: Object,
    showErrorAlert: { default: false, type: Boolean },
    dismissSecs: { default: 10, type: Number },
    dismissSuccessAlert: { default: 0, type: Number }
  },
  data() {
    return {
      successMessage: {},
      errorMessage: {},
      isShowError: false,
      delaySecs: 10,
      delaySuccessAlert: 0,
    }
  },
  watch: {
    reloadParams() {
      this.successMessage = this.successAlertMessage,
      this.errorMessage = this.errorAlertMessage,
      this.isShowError = this.showErrorAlert,
      this.delaySecs = this.dismissSecs,
      this.delaySuccessAlert = this.isShowError ? 0 : this.dismissSuccessAlert
    }
  },
  computed: {
    reloadParams() {
      return [
        this.successAlertMessage,
        this.errorAlertMessage,
        this.showErrorAlert,
        this.dismissSecs,
        this.dismissSuccessAlert
      ];
    }
  }
}
</script>