import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth";

Vue.use(Vuex)

const state = {
  // sidebarShow: 'responsive',
  sidebarShow: false,
  sidebarMinimize: true,
  asideShow: false,
  darkMode: false,

  // For Filter
  filters: {
    show: true,

    orders: {
      search: null,
      selectedOrderStatus: 'all',
      startDate: null,
      endDate: null,
    },

    dashboard: {
      selectedType: [],
      showInactive: false
    },

    pool: {
      firmType: 'manufacturer',
    },
  },

  // Product Asides
  asideAddToWarehouse: false,

  // MC
  selectedFirm: JSON.parse(localStorage.getItem('selectedFirm')) ?? null,

  // * Auth
  errors: []
}

const mutations = {
  setSelectedFirm(state, firm) {
    state.selectedFirm = firm;
    localStorage.setItem("selectedFirm", JSON.stringify(firm));
  },

  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  toggle(state, variable) {
    state[variable] = !state[variable]
  },

  // * Auth
  setErrors(state, errors) {
    state.errors = errors;
  }
}

// * Auth
const getters = {
  errors: state => state.errors
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions: {},
  modules: {
    auth
  }
})
