import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/plugins/i18n_single_language_file'

Vue.use(Router)

// const guest = (to, from, next) => {
//   if (!localStorage.getItem("authToken")) {
//     return next();
//   } else {
//     return next({ name: 'Home' })
//   }
// };
// const auth = (to, from, next) => {
//   // to.meta.label = i18n.t(to.meta.label) // meta.title is keypath of locale messages

//   if (localStorage.getItem("authToken") && localStorage.getItem("authData")) {
//     return next();
//   } else {
//     // Redirect to requested page after login
//     const loginpath = window.location.pathname;
//     return next({ name: 'Login', query: { from: loginpath } });
//   }
// };


const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Auth/Login')
    },
    {
      path: '/register',
      name: 'Register',
      // beforeEnter: guest,
      component: () => import('@/views/Auth/Register-wizard')
    },

    // Dashboard
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: { translated: true, label: "➜" },
      // beforeEnter: auth,
      component: () => import('@/containers/TheContainer'),

      children: [
        {
          path: 'dashboard/:event?',
          name: 'Dashboard',
          meta: { translated: false, label: "sidebar.dashboard" },
          component: () => import('@/views/Dashboard')
        },
        {
          path: '403',
          meta: { label: 'HTTP 403 Forbidden' },
          name: 'Page403',
          component: () => import('@/views/Auth/Page403')
        },

        //#region Users
        {
          path: 'users',
          meta: { translated: false, label: "user.users" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: '',
            name: 'Users',
            meta: { translated: false, label: "user.all_users" },
            component: () => import('@/views/users/List')
          },
          {
            path: 'add',
            name: 'Add New User',
            meta: { translated: false, label: "user.add_new_user" },
            component: () => import('@/views/users/Item')
          },
          {
            path: ':id',
            name: 'Edit User',
            meta: { translated: false, label: "user.edit_user" },
            component: () => import('@/views/users/Item')
          }
          ]
        },
        //#endregion

        //#region Firms
        {
          path: 'firms',
          meta: { translated: false, label: "firm.firms" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: 'all',
            name: 'Firms',
            meta: { translated: false, label: "firm.all_firms" },
            component: () => import('@/views/firms/List')
          },
          {
            path: 'dashboard',
            alias: [''],
            name: 'Firm Dashboard',
            meta: { translated: false, label: "firm.dashboard" },
            component: () => import('@/views/firms/Dashboard')
          },
          {
            path: ':id',
            name: 'Edit Firm',
            meta: { translated: false, label: "firm.edit_firm" },
            component: () => import('@/views/firms/Item')
          }
          ]
        },
        //#endregion

        //#region Suppliers
        {
          path: 'suppliers',
          meta: { translated: false, label: "firm.firms" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'products',
              meta: { translated: false, label: "product.products" },
              component: { render(c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'SupplierProducts',
                  meta: { translated: false, label: "product.products" },
                  component: () => import('@/views/suppliers/products/List'),
                },
                {
                  path: 'add',
                  name: 'Add New Supplier Product',
                  meta: { translated: false, label: "product.add_new_product" },
                  component: () => import('@/views/suppliers/products/Item')
                },
                {
                  path: ':id',
                  name: 'Edit Supplier Product',
                  meta: { translated: false, label: "product.edit_product" },
                  component: () => import('@/views/suppliers/products/Item')
                }
              ]
            },
            {
              path: 'buyer-firms',
              name: 'BuyerFirms',
              meta: { translated: false, label: "firm.buyer_firms" },
              component: () => import('@/views/suppliers/BuyerFirms')
            },

            //#region HappyGast
            {
              path: ':happy_gast_id',
              name: 'Supplier Dashboard With HappyGast Id',
              meta: { translated: false, label: "restaurant.dashboard" },
              component: () => import('@/views/firms/Dashboard')
            },
            //#endregion
          ]
        },
        //#endregion

        //#region Products
        {
          path: 'products',
          // meta: { label: 'Ürünler' },
          meta: { translated: false, label: "product.products" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: '',
            name: 'Products',
            // meta: { label: 'Tüm Ürünler' },
            meta: { translated: false, label: "product.all_products" },
            component: () => import('@/views/products/List')
          },
          {
            path: 'bulk',
            name: 'Add Bulk Product',
            meta: { translated: false, label: "product.bulk.add_bulk_product" },
            component: () => import('@/views/products/bulk/Excel')
          },
          {
            path: 'pool',
            name: 'Product Pool',
            meta: { translated: false, label: "product.product_pool" },
            component: () => import('@/views/products/pool/List')
          },
          {
            path: 'add',
            name: 'Add New Product',
            // meta: { label: 'Yeni Ürün Ekle' },
            meta: { translated: false, label: "product.add_new_product" },
            component: () => import('@/views/products/Item')
          },
          {
            path: ':id',
            name: 'Edit Product',
            // meta: { label: 'Ürünü Düzenle' },
            meta: { translated: false, label: "product.edit_product" },
            component: () => import('@/views/products/Item')
          }
          ]
        },
        //#endregion

        //#region PGroups
        {
          path: 'product-groups',
          meta: { translated: false, label: "pgroup.pgroups" },
          component: { render(c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'PGroups',
              meta: { translated: false, label: "pgroup.pgroups" },
              component: () => import('@/views/pgroups/List')
            },
            {
              path: 'add',
              name: 'Add New PGroup',
              meta: { translated: false, label: "pgroup.add_new_pgroup" },
              component: () => import('@/views/pgroups/Item')
            },
            {
              path: ':id',
              name: 'Edit PGroup',
              meta: { translated: false, label: "pgroup.edit_pgroup" },
              component: () => import('@/views/pgroups/Item')
            },
          ]
        },

        //#region Warehouses
        {
          path: 'warehouses',
          // meta: { label: 'Depo' },
          meta: { translated: false, label: "warehouse.warehouse" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [{
            path: '',
            name: 'Warehouses',
            // meta: { label: 'Depoları Listele' },
            meta: { translated: false, label: "warehouse.warehouses" },
            component: () => import('@/views/warehouses/List')
          },
          {
            path: 'add',
            name: 'Add New Warehouse',
            // meta: { label: 'Yeni Depo Ekle' },
            meta: { translated: false, label: "warehouse.add_new_warehouse" },
            component: () => import('@/views/warehouses/Item')
          },

          // Warehouse Products
          {
            path: ':id/products',
            name: 'WarehouseProducts',
            // meta: { label: 'Depo Ürünleri' },
            meta: { translated: false, label: "warehouse.warehouse_products" },
            component: () => import('@/views/warehouses/products/List')
          },
          {
            path: ':id/products/:product_id',
            name: 'Edit Warehouse Product',
            // meta: { label: 'Depo Ürünü Düzenle' },
            meta: { translated: false, label: "warehouse.edit_warehouse_product" },
            component: () => import('@/views/warehouses/products/Item')
          },
          {
            path: 'products',
            name: 'AllWarehouseProducts',
            // meta: { label: 'Depo Ürünleri' },
            meta: { translated: false, label: "warehouse.warehouse_products" },
            component: () => import('@/views/warehouses/products/List')
          },

          // Shipping Areas
          {
            path: ':id/shipping-areas',
            name: 'ShippingAreas',
            // meta: { label: 'Sevkiyat Bölgeleri' },
            meta: { translated: false, label: "warehouse.shipping_areas" },
            component: () => import('@/views/warehouses/shipping_areas/List')
          },
          {
            path: ':id/shipping-areas/add',
            name: 'Add New ShippingArea',
            // meta: { label: 'Yeni Sevkiyat Bölgesi Ekle' },
            meta: { translated: false, label: "warehouse.add_new_shipping_area" },
            component: () => import('@/views/warehouses/shipping_areas/Item')
          },
          {
            path: 'shipping-areas',
            name: 'AllShippingAreas',
            // meta: { label: 'Sevkiyat Bölgeleri' },
            meta: { translated: false, label: "warehouse.shipping_areas" },
            component: () => import('@/views/warehouses/shipping_areas/List')
          },

          // Edit Warehouse
          {
            path: ':id',
            name: 'Edit Warehouse',
            // meta: { label: 'Depo Düzenle' },
            meta: { translated: false, label: "warehouse.edit_warehouse" },
            component: () => import('@/views/warehouses/Item')
          },
          ]
        },
        //#endregion

        //#region Orders
        // {
        //   path: 'orders',
        //   meta: { translated: false, label: "order.orders" },
        //   component: { render(c) { return c('router-view') } },
        //   children: [
        //     {
        //       path: 'send',
        //       name: 'Send Orders',
        //       meta: { translated: false, label: "order.send_orders" },
        //       component: () => import('@/views/orders/Send')
        //     }
        //   ]
        // },
        //#endregion

        //#region Customer Orders
        {
          path: 'customer-orders',
          meta: { translated: false, label: "order.orders" },
          component: { render(c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'Orders',
              meta: { translated: false, label: "order.customer_orders" },
              component: () => import('@/views/orders/customer/List'),
            },
            {
              path: ':str_id',
              name: 'Order Details',
              meta: { translated: false, label: "order.details" },
              component: () => import('@/views/orders/customer/Details')
            }
          ]
        },
        //#endregion

        //#region Supplier Orders
        {
          path: 'supplier-orders',
          component: { render(c) { return c('router-view') } },
          meta: { translated: false, label: "order.orders" },
          children: [
            // Supplier Orders
            {
              path: '',
              name: 'SupplierOrders',
              meta: { translated: false, label: "order.supplier_orders" },
              component: () => import('@/views/orders/supplier/List')
            },

            // Check Supplier Orders
            {
              path: 'check',
              meta: { translated: false, label: "order.supplier_orders" },
              component: { render(c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'CheckSupplierOrders',
                  meta: { translated: false, label: "order.order_check" },
                  component: () => import('@/views/orders/supplier/check/List')
                },
                {
                  path: ':id',
                  name: 'CheckSupplierOrderDetails',
                  meta: { translated: false, label: "order.details" },
                  component: () => import('@/views/orders/supplier/check/Item')
                }
              ]
            }
          ]
        },
        //#endregion

        //#region Restaurant
        {
          path: 'restaurant',
          meta: { translated: false, label: "restaurant.restaurant" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'dashboard',
              alias: [''],
              name: 'Restaurant Dashboard',
              meta: { translated: false, label: "restaurant.dashboard" },
              component: () => import('@/views/firms/Dashboard')
            },

            //#region Menus
            {
              path: 'menus',
              meta: { translated: false, label: "menu.menus" },
              component: { render(c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'Menus',
                  meta: { translated: false, label: "menu.menus" },
                  component: () => import('@/views/restaurants/menus/List')
                },
                {
                  path: 'add',
                  name: 'Add New Menu',
                  meta: { translated: false, label: "menu.add_new_menu" },
                  component: () => import('@/views/restaurants/menus/Item')
                },
                {
                  path: 'products/bulk',
                  name: 'Add Bulk Restaurant Product',
                  meta: { translated: false, label: "product.bulk.add_bulk_product" },
                  component: () => import('@/views/products/bulk/Excel')
                },
                {
                  path: ':menu_id?/products',
                  name: 'Menu Products',
                  meta: { translated: false, label: "menu.menu_products" },
                  // component: () => import('@/views/restaurants/menus/products/List')
                  component: () => import('@/views/products/List')
                },
                {
                  path: ':menu_id?/products/add',
                  name: 'Add New Menu Product',
                  meta: { translated: false, label: "product.add_new_product" },
                  component: () => import('@/views/products/Item')
                },
                {
                  path: ':menu_id?/products/:id',
                  name: 'Edit Menu Product',
                  meta: { translated: false, label: "product.edit_product" },
                  component: () => import('@/views/products/Item')
                },

                {
                  path: ':id',
                  name: 'Edit Menu',
                  meta: { translated: false, label: "menu.edit_menu" },
                  component: () => import('@/views/restaurants/menus/Item')
                },
              ]
            },
            //#endregion

            //#region Extras
            {
              path: 'extras',
              meta: { translated: false, label: "extra.extras" },
              component: { render(c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'Extras',
                  meta: { translated: false, label: "extra.extras" },
                  component: () => import('@/views/restaurants/extras/List')
                },
                {
                  path: 'add',
                  name: 'Add New Extra',
                  meta: { translated: false, label: "extra.add_new_extra" },
                  component: () => import('@/views/restaurants/extras/Item')
                },
                {
                  path: ':id',
                  name: 'Edit Extra',
                  meta: { translated: false, label: "extra.edit_extra" },
                  component: () => import('@/views/restaurants/extras/Item')
                },
              ]
            },
            //#endregion

            //#region Options
            {
              path: 'options',
              meta: { translated: false, label: "product.options.options" },
              component: { render(c) { return c('router-view') } },
              children: [
                {
                  path: '',
                  name: 'AllOptions',
                  meta: { translated: false, label: "product.options.options" },
                  component: () => import('@/views/restaurants/options/Item')
                },
              ]
            },
            //#endregion

            //#region HappyGast
            {
              path: ':happy_gast_id',
              name: 'Restaurant Dashboard With HappyGast Id',
              meta: { translated: false, label: "restaurant.dashboard" },
              component: () => import('@/views/firms/Dashboard')
            },
            //#endregion

            //#region Cashpad
            {
              path: 'cashpad',
              meta: { translated: false, label: "cashpad.cashpad" },
              component: {
                render(c) {
                  return c('router-view')
                }
              },
              children: [
                {
                  path: '',
                  name: 'Cashpad',
                  meta: { translated: false, label: "cashpad.cashpad_info" },
                  component: () => import('@/views/cashpad/Item')
                },
                {
                  path: 'products',
                  name: 'Cashpad Products',
                  meta: { translated: false, label: "cashpad.products" },
                  component: () => import('@/views/cashpad/Products')
                },
                {
                  path: 'extras',
                  name: 'Cashpad Extras',
                  meta: { translated: false, label: "cashpad.extras" },
                  component: () => import('@/views/cashpad/Extras')
                },
                {
                  path: 'ingredients',
                  name: 'Cashpad Ingredients',
                  meta: { translated: false, label: "cashpad.products_used_as_ingredients" },
                  component: () => import('@/views/cashpad/Ingredients')
                }
              ]
            },
            //#endregion
          ]
        },
        //#endregion

        //#region Atrify
        {
          path: 'atrify',
          meta: { translated: false, label: "atrify.atrify" },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'items',
              name: 'AtrifyItems',
              meta: { translated: false, label: "atrify.atrify_items" },
              component: () => import('@/views/atrify/List')
            }
          ]
        },
        //#endregion
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {

  // If there is no company selected
  const sf = router.app.selectedFirm;
  if (typeof sf !== 'undefined') {
    //(!['Login', 'Dashboard'].includes(to.name)) && !(sf) && next({ name: 'Dashboard' });
  }

  // Route translator
  if (!to.meta.translated) {
    to.meta.label = to.meta.label ? i18n.t(to.meta.label) : null;
    to.meta.translated = true;
  }

  to.matched.forEach(parentRoute => {
    if (!parentRoute.meta.translated) {
      parentRoute.meta.label = parentRoute.meta.label ? i18n.t(parentRoute.meta.label) : null;
      parentRoute.meta.translated = true;
    }
  });

  // Navigation Guards
  const authToken = localStorage.getItem("authToken");
  const isAuthenticated = "authToken" in localStorage && "authData" in localStorage;

  if (isAuthenticated) {
    next();
  } else if (!['Login', 'Register'].includes(to.name)) {
    const loginpath = window.location.pathname,
      searchParams  = new URL(window.location.href).searchParams,
      HappyGastAuth = searchParams.get("HappyGastAuth"),
      HappyGastLokation = searchParams.get("HappyGastLokation"),
      query = HappyGastAuth
        ? { from: loginpath, HappyGastAuth, HappyGastLokation }
        : { from: loginpath };

    next({ name: "Login", query: query });
  } else {
    next();
  }
})

export default router
