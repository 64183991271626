import i18n from '@/plugins/i18n_single_language_file'

export const globalFunc = {
  slugify: (text, ampersand = 'and') => {
    const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧğşı'
    const b = 'aaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzhgsi'
    const p = new RegExp(a.split('').join('|'), 'g')

    return text.toString().toLowerCase()
      .replace(/[\s_]+/g, '-')                  // Replace whitespace and underscore with single hyphen
      .replace(p, c => b.charAt(a.indexOf(c)))  // Replace special chars
      .replace(/&/g, `-${ampersand}-`)          // Replace ampersand with custom word
      .replace(/[^\w-]+/g, '')                  // Remove all non-word chars
      .replace(/--+/g, '-')                     // Replace multiple hyphens with single
      .replace(/^-+|-+$/g, '')                  // Remove leading and trailing hyphens
  },

  isURL: (str) => {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  },

  getValidImageUrl: (url) => {
    return globalFunc.isURL(url) ? url : process.env.VUE_APP_ORIGIN_CDN + url;
  }
}


export const globalVar = {
  // stockUnits: [
  //   { value: 'piece', label: i18n.t('global.piece') },
  // ],
  currency: [
    { value: 'EUR', label: 'EUR' },
    { value: 'TRY', label: 'TRY' },
    { value: 'USD', label: 'USD' },
  ],
  tax: [
    { value: '0.00', label: '---' },
    { value: '0.07', label: '% 7' },
    { value: '0.08', label: '% 8' },
    { value: '0.09', label: '% 9' },
    { value: '0.17', label: '% 17' },
    { value: '0.18', label: '% 18' },
    { value: '0.19', label: '% 19' },
  ],
  packagingTypes: [
    { value: 'CR', label: i18n.t('packaging.type_codes.CR') },
  ],
}
