<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="sass">
@import "assets/scss/style"
@import "~pretty-checkbox/src/pretty-checkbox.scss"
</style>
