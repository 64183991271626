import axios from "axios";
import store from "../store";
import router from '../router'

export const axiosInstance = axios.create({
  baseURL: router.baseURL
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    switch (error.response.status) {
      case 422:
        store.commit("setErrors", error.response.data.errors);
        return error.response; // 27.12.2021 tarihinde user/add için eklendi.

      case 401: // Unauthorized access!
        store.commit("auth/setUserData", null);
        localStorage.removeItem("authToken");

        // if (router.history.current.name !== 'Login')
        //   router.push({ name: "Login" });
        // else
        //   return error.response;
        // break;
        return error.response;

      case 403: // Forbidden: You don't have permission!
        router.push({ name: "Page403" });
        break;

      default:
        return Promise.reject(error);
    }
  });
