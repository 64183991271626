import Vue from 'vue'

Vue.filter('ucfirst', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toString().toUpperCase()
});

Vue.filter('toCurrency', function (value, currency = 'EUR') {
  value = parseFloat(value);
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});


// http://www.google.com -> http://www.google.com
// ftp://google.com      -> ftp://google.com
// www.google.com        -> http://www.google.com
// google.com            -> http://google.com
Vue.filter('addUriScheme', function (value) {
  if (!value)
    return ''

  return (value.indexOf('://') === -1) ? 'https://' + value : value;
});
