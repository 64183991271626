import 'core-js/stable'
import Vue from 'vue'
import CoreuiVuePro from '@coreui/vue-pro'

import App from './App'
import router from './router/index'
import store from './store/index'
import i18n from './plugins/i18n_single_language_file'
import VueConfirmDialog from 'vue-confirm-dialog'
import { freeSet as icons } from '@coreui/icons'      // import { iconsSet as icons} from './assets/icons/icons.js'
import VueQuillEditor from 'vue-quill-editor'         // Text Editors

import backend from "./router/backend.js";            // Api Routing Table
import { axiosInstance } from './helper/axios.js'     // Global Axios
import { globalFunc, globalVar } from './helper/global.js'  // MC Global Functions/Variables
import AlertSection from './components/AlertSection'  // MC Global Components
import Spinner from './components/Spinner'            // MC Global Components
import "./filters/global.js";                         // Global filters

import { mapActions, mapState } from "vuex";
import VueImg from 'v-img';
import PrettyCheck from 'pretty-checkbox-vue/check';

//#region Vue Toastification v1 (Vue 3)
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the CSS or use your own!
const toast_options = {
  // position: POSITION.TOP_CENTER,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
}
//#endregion


//#region Vuelidate Error Extractor 2
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
Vue.use(VuelidateErrorExtractor, {
  i18n: false,
  // Define common validation messages.
  messages: {
    required: "{attribute} is required!",
    email: "{attribute} is not a valid Email address!",
    minLength: "{attribute} is a required field and must be at least {min} characters.",
    minDate: "{attribute} must not be less than Today or From Date!",
  }
});
//#endregion


// Floating Vue
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'


//#region Mixin
// mixin içerisindeki tüm option'lar (data, method, computed prop…) component'in kendi option'ları içerisinde 'mixed' olarak yer alır.
Vue.mixin({
  computed: {
    ...mapState({
      auth_user: state => state.auth.userData,
      auth_user_roles: state => state.auth.userData && state.auth.userData.roles.length > 0 ? state.auth.userData.roles.map(role => role.name) : ['Admin'],
      selectedFirm: state => state.selectedFirm,
    }),
    isSuperAdmin() { return this.auth_user && this.auth_user.roles.includes("Super Admin") },
    isRestaurant() { return this.selectedFirm && this.selectedFirm.type == "R" },
    isManufacturer() { return this.selectedFirm && this.selectedFirm.type == "M" },
    isSupplier() { return this.selectedFirm && this.selectedFirm.type == "S" },
  }
})
//#endregion


//#region Vue Select
// Custom Components
import vSelect from 'vue-select';
// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('span', '❌'),
  },
  // OpenIndicator: {
  //   render: createElement => createElement('span', '🔽'),
  // },
});
//#endregion


//#region idle-vue
// idle-vue is a Vue.js plug-in, that detects when the user hasn't interacted with your app for a while.
// https://www.npmjs.com/package/idle-vue
import IdleVue from "idle-vue";
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 10 * 60 * 1000, // 10 minutes
  startAtIdle: false
});
//#endregion


Vue.use(CoreuiVuePro)
Vue.use(VueConfirmDialog)
Vue.use(VueQuillEditor)
Vue.use(VueImg);
Vue.use(Toast, toast_options);
Vue.use(FloatingVue);

// Components
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('alert-section', AlertSection);
Vue.component('mc-spinner', Spinner);
Vue.component('p-check', PrettyCheck);
Vue.component('formSummary', templates.multiErrorExtractor.foundation6)

// Prototypes
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$backend = backend;
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$globalFunc = globalFunc;
Vue.prototype.$globalVar = globalVar;


new Vue({
  el: '#app',
  router,
  store,
  icons,
  i18n,
  template: '<App/>',
  components: {
    App,
  },

  methods: {
    // Sayfanın yenilenmesi (F5) durumunda:
    ...mapActions("auth", ["getAuthData"]),
  },
  created() {
    // Javascript Array except (keys)
    Array.prototype.except = function(keys) {
      return this.filter((x, index, arr) => {
        return typeof keys === "number"
          ? index !== keys
          : keys.indexOf(index) === -1;
      });
    };

    Array.prototype.sortArray = function(key) {
      // Set slice() to avoid to generate an infinite loop!
      return this.slice().sort(function(a, b) {
        return a[key] - b[key];
      });
    };
  },
  mounted() {
    // Sayfanın yenilenmesi (F5) durumunda:
    this.getAuthData();

    if (!this.selectedFirm && this.$route.path !== "/") {
      router.push({ name: "Dashboard" });
    }
  }
});
