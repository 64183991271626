import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Import the splitLangJson methods
import { splitLangJson } from './single_language_file.js'

// Import your big language file
import langs from './langs.json?raw'

Vue.use(VueI18n)

export default new VueI18n({
  locale: getLang(),
  fallbackLocale: 'tr',
  messages: splitLangJson(langs) // Call the splitLangJson method, with the JSON object
})

function getLang() {
  const lang = localStorage.getItem("lang");
  return lang ?? getBrowserLocale();
}

function getBrowserLocale() {
  const navigatorLocale =
    navigator.languages !== undefined ?
    navigator.languages[0] :
    navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  const browserLang = navigatorLocale.trim().split(/-|_/)[0];
  localStorage.setItem("lang", browserLang);
  return browserLang;
}