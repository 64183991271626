<template>
  <CElementCover class="element-cover" :opacity="opacity" :center="center" v-show="show">
    <div
      class="d-flex justify-content-center flex-row"
      :style="!center ? ('margin-top: ' + mtop) : ''"
    >
      <h3 v-if="!hideText" class="mr-3">{{ $t('global.please_wait') }}</h3>
      <div class="sk-wave">
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
      </div>
    </div>
  </CElementCover>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    show: { default: false, type: Boolean },
    center: { default: false, type: Boolean },
    opacity: { default: false, type: Number },
    hideText: { default: false, type: Boolean },
    mtop: { default: '5rem', type: String },
  },
  data() {
    return {};
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>

<style>
.element-cover {
  z-index: 99;
}
.sk-wave-rect {
  background-color: #d60b52;
}
</style>