// import axios from "axios";
import { axiosInstance } from '../helper/axios.js'
import backend from "../router/backend";
import store from './index'


// *.js dosyalarından 'authenticated user' bilgilerine erişmek için eklendi.
export const authService = {
  get currentUser() {
    return JSON.parse(localStorage.getItem("authData"));
  }
};


export default {
  namespaced: true,

  state: {
    userData: null
  },

  getters: {
    userData: state => state.userData
  },

  mutations: {
    setUserData(state, user) {
      state.userData = user;
    }
  },

  actions: {
    // Checks if the user is authenticated
    checkAuth() {
      axiosInstance.get(backend.get('AUTH', 'CHECK'));
    },

    // User Profile (Sayfanın yenilenmesi (F5) durumunda)
    async getAuthData({ dispatch, commit, state }) {
      dispatch("checkAuth");

      if (localStorage.getItem("authToken") && localStorage.getItem("authData")) {
        commit("setUserData", JSON.parse(localStorage.getItem('authData')));

        if (localStorage.getItem("selectedFirm")) {
          store.commit('set', ['selectedFirm', JSON.parse(localStorage.getItem('selectedFirm'))]);
        } else {
          // store.commit('set', ['selectedFirm', state.userData.firms[0]]);
          // localStorage.setItem("selectedFirm", JSON.stringify(state.userData.firms[0]));
        }
      } else {
        localStorage.removeItem("authToken");
        localStorage.removeItem("authData");
      }
    },

    async getUserData({ commit }) {
      await axiosInstance.get(backend.get('AUTH', 'PROFILE'))
        .then(response => {

          commit("setUserData", response.data.data);
          localStorage.setItem("authData", JSON.stringify(response.data.data));

          if (!store.state.selectedFirm && response.data.data.firms) {
            // store.commit('set', ['selectedFirm', response.data.data.firms[0]]);
            // localStorage.setItem("selectedFirm", JSON.stringify(response.data.data.firms[0]));
          }
          else {
            store.commit('set', ['selectedFirm', JSON.parse(localStorage.getItem('selectedFirm'))]);
          }

        })
        .catch(() => {
          localStorage.removeItem("selectedFirm");
          localStorage.removeItem("authToken");
          localStorage.removeItem("authData");
        });
    },

    // Login
    async sendLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      const response = await axiosInstance.post(backend.get('AUTH', 'LOGIN'), data);

      if (!['login_validation_error', 'credentialing_error', 'unconfirmed_account'].includes(response.data.status)) {

        commit("setUserData", response.data.data);
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("authData", JSON.stringify(response.data.data));

        if (!store.state.selectedFirm && response.data.data.firms) {
          // store.commit('set', ['selectedFirm', response.data.data.firms[0]]);
          // localStorage.setItem("selectedFirm", JSON.stringify(response.data.data.firms[0]));
        }
        else {
          localStorage.setItem("selectedFirm", JSON.stringify(store.state.selectedFirm));
        }
      }

      return response;
    },

    // HappyGast Login
    async sendHappyGastLoginRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      const response = await axiosInstance.post(backend.get('AUTH', 'HAPPYGAST_LOGIN'), data);

      if (response.status === 200) {
        commit("setUserData", response.data.data);
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("authData", JSON.stringify(response.data.data));

        if (!store.state.selectedFirm && response.data.data.selected_firm) {
          store.commit('set', ['selectedFirm', response.data.data.selected_firm]);
          localStorage.setItem("selectedFirm", JSON.stringify(response.data.data.selected_firm));
        }
        else {
          localStorage.setItem("selectedFirm", JSON.stringify(store.state.selectedFirm));
        }
      }

      return response;
    },

    // Register
    async sendRegisterRequest({ commit }, data) {
      commit("setErrors", {}, { root: true });
      const response = await axiosInstance.post(backend.get('AUTH', 'REGISTER'), data);

      if (!['error', 'Unconfirmed Account'].includes(response.data.status)) {
        commit("setUserData", response.data.data);
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("authData", JSON.stringify(response.data.data));
      }
      return response;
    },

    // Logout
    sendLogoutRequest({ commit }) {
      axiosInstance.post(backend.get('AUTH', 'LOGOUT')).then(() => {
        commit("setUserData", null);
        store.commit('set', ['selectedFirm', null]);

        localStorage.removeItem("authToken");
        localStorage.removeItem("authData");
        localStorage.removeItem("selectedFirm");
      });
    },

    // sendVerifyResendRequest() {
    //   return axiosInstance.get(process.env.VUE_APP_API_URL + "email/resend");
    // },

    // sendVerifyRequest({ dispatch }, hash) {
    //   return axiosInstance.get(process.env.VUE_APP_API_URL + "email/verify/" + hash)
    //     .then(() => {
    //       dispatch("getUserData");
    //     });
    // }

  }
};
